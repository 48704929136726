import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("./components/LandingPage.vue"),
  },
  {
    path: "/cotizar",
    component: () => import("./components/Cotizador.vue"),
  },
  {
    path: "/blog",
    component: () => import("./components/BlogPosts.vue"),
  },
  {
    path: "/blog/:id",
    component: () => import("./components/BlogPost.vue"),
    props: true,
  },
  {
    path: "/post/:slug",
    component: () => import("./components/BlogPost.vue"),
    props: true,
  },
  {
    path: "/blog/:hash/:titulo",
    component: () => import("./components/BlogPost.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
