<template>
  <div id="footer">
    <div class="containerr mx-auto">
      <div class="w-screen py-20 px-20">
        <div class="grid grid-cols-1 place-content-center text-center">
          <h1 class="text-3xl mb-5">
            CONTÁCTANOS PARA OBTENER NUESTRO SERVICIO DE SOFTWARE
          </h1>
          <p class="">
            Gracias por su interés. Para poder apoyarlo, por favor comuníquese,
            lo atenderemos con gusto, agendemos una videollamada para mostrar
            nuestro catálogo de software y escuchar sus necesidades.
          </p>
        </div>
      </div>
      <div class="w-screen bg-white">
        <div class="container mx-auto">
          <div class="grid grid-cols-1 text-center">
            <p class="tracking-widest text-6xl">
              <img src="assets/icono.svg" class="h-16 inline-block" /> ittoni
            </p>
            <p>Tecnología para la gestión empresarial</p>
            <div
              class="px-10 md:px-0 py-4 md:py-8 text-md text-gray-700 tracking-widest"
            >
              <div
                class="grid grid-cols-1 md:grid-cols-5 gap-4 place-content-center text-center"
              >
                <a
                  href="https://wa.me/+524444130935"
                  target="_blank"
                  class="rounded-md bg-gris text-white p-2"
                >
                  <i class="fa-brands fa-whatsapp" /> Ittoni
                </a>
                <a
                  href="mailto:consultoria@ittoni.xyz"
                  class="rounded-md bg-gris text-white p-2"
                  >consultoria@ittoni.xyz</a
                >
                <a
                  href="https://instagram.com/ittoni.mx"
                  target="_blank"
                  class="rounded-md bg-gris text-white p-2"
                >
                  <i class="fa-brands fa-instagram" />
                  /ittoni.mx
                </a>
                <a
                  href="https://facebook.com/ittoni.mx"
                  target="_blank"
                  class="rounded-md bg-gris text-white p-2"
                >
                  <i class="fa-brands fa-facebook-square" />/ittoni.mx
                </a>
                <a
                  href="https://linkedin.com/company/ittoni"
                  target="_blank"
                  class="rounded-md bg-gris text-white p-2"
                >
                  <i class="fa-brands fa-linkedin" /> Ittoni
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-gray-50 w-full h-16 grid grid-cols-1 place-content-center text-gris text-center text-xs"
    >
      <p>Todos los derechos reservados &reg; {{ year }} ITTONI</p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "IttoniFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
});
</script>
