<template>
  <nav class="w-screen bg-gris text-white fixed z-40 top-0 left-0">
    <div class="container mx-auto">
      <div
        class="grid grid-cols-1 md:grid-cols-3 p-3 text-gray-300 place-content-center"
      >
        <div class="grid grid-cols-1 w-full place-content-center">
          <a
            class="text-left w-full text-2xl tracking-widest font-bold"
            href="#/"
          >
            <img
              src="assets/ittoni.svg"
              class="h-6 md:h-9 mb-1 inline"
            />&nbsp;<span class="inline">ittoni</span>
          </a>
        </div>
        <div class="md:hidden fixed top-4 right-4" @click="mostrarMenu = true">
          <h1 class="text-xl">
            <i class="fa fa-bars" />
          </h1>
        </div>
        <div
          class="mt-1 md:col-span-2 text-center md:text-right text-sm md:text-lg hidden md:inline"
        >
          <a
            class="mx-2 pl-2"
            v-for="i in items"
            :key="i.label"
            :href="i.link"
            >{{ i.label }}</a
          >
          <a
            class="mx-2 px-3 py-2 rounded-md md:bg-gray-700"
            target="_blank"
            href="https://app.ittoni.xyz"
            >Iniciar sesión</a
          >
        </div>
      </div>
    </div>
    <a
      href="https://wa.me/+524444130935"
      target="_blank"
      class="fixed top-16 md:top-20 right-4 md:right-5 z-50 bg-verde text-white text-md md:text-xl py-1 px-3 rounded-md"
      v-if="!mostrarMenu && mostrarBotonCotizar"
    >
      Consulta
    </a>
    <!--router-link
      to="/cotizar"
      class="fixed top-16 md:top-20 right-4 md:right-5 z-50 bg-verde text-white text-md md:text-xl py-1 px-3 rounded-md"
      v-if="!mostrarMenu && mostrarBotonCotizar"
    >
      Comprar ahora
    </router-link-->
    <div
      v-if="mostrarMenu"
      class="fixed top-0 left-0 w-screen h-screen bg-gris grid grid-cols-1 place-content-center text-center"
    >
      <div class="fixed top-4 right-4 text-xl">
        <p @click="mostrarMenu = false">
          <i class="fa fa-circle-xmark" />
        </p>
      </div>
      <p>
        <img src="assets/ittoni.svg" class="h-12 inline mb-2" />
      </p>
      <h1 class="text-2xl mb-12">Menú de ITTONI</h1>
      <ul @click="mostrarMenu = false">
        <li v-for="i in items" :key="i.label" class="mb-2 text-lg">
          <a :href="i.link">{{ i.label }}</a>
        </li>
        <li>&nbsp;</li>
        <li class="mb-2 text-lg">
          <a class="p-2 rounded-md bg-slate-700">Comprar ahora</a>
        </li>
        <li class="my-4 text-lg">
          <a class="p-2 rounded-md bg-slate-700">Iniciar sesión</a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "IttoniHeader",
  data() {
    return {
      mostrarMenu: false,
      items: [
        {
          label: "Software",
          link: "#software",
        },
        {
          label: "Blog",
          link: "#/blog",
        },
        {
          label: "Consultoría",
          link: "#consultoria",
        },
      ],
    };
  },
  computed: {
    mostrarBotonCotizar: function () {
      return this.$route.path != "/cotizar";
    },
  },
});
</script>
