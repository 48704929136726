<template>
  <div class="relative">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { RouterView } from "vue-router";
export default {
  name: "App",
  components: { RouterView, Header, Footer },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  watch: {
    "$route.path": function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>
